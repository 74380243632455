<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="店铺" prop="shopId">
                <a-select
                  v-model="shopData"
                  mode="multiple"
                  label-in-value
                  placeholder="请选择作者"
                  style="width: 100%"
                  :filter-option="false"
                  :not-found-content="fetching ? undefined : null"
                  :options="shopOptions"
                  @search="fetchShopOptions"
                  @change="fetchShopChange"
                >
                  <template v-if="fetching" #notFoundContent>
                    <a-spin size="small" />
                  </template>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="剧本" prop="scriptId">
                <a-select
                  v-model="scriptData"
                  mode="multiple"
                  label-in-value
                  placeholder="请选择剧本"
                  style="width: 100%"
                  :filter-option="false"
                  :not-found-content="fetching ? undefined : null"
                  :options="scriptOptions"
                  @search="fetchScriptOptions"
                  @change="fetchScriptChange"
                >
                  <template v-if="fetching" #notFoundContent>
                    <a-spin size="small" />
                  </template>
                </a-select>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
<!--      <div class="table-operations">-->
<!--        <a-button type="primary" @click="handleExport" v-hasPermi="['shop:script/edition/audit:export']">-->
<!--          <a-icon type="download" />导出-->
<!--        </a-button>-->
<!--        <table-setting-->
<!--          :style="{float: 'right'}"-->
<!--          :table-size.sync="tableSize"-->
<!--          v-model="columns"-->
<!--          :refresh-loading="loading"-->
<!--          @refresh="getList" />-->
<!--      </div>-->
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider v-if="record.status === 1" type="vertical" v-hasPermi="['shop:script/edition/audit:audit']" />
          <a v-if="record.status === 1" @click="auditDetail(record)" v-hasPermi="['shop:script/edition/audit:audit']">
            <a-icon type="audit" />审批
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { listScriptEditionAudit, delScriptEditionAudit, audit } from '@/api/shop/scriptEditionAudit'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
import Template from '@/views/sms/template/index.vue'
import { loadOptionsByName } from '@/api/shop/shop'
import { loadScriptOptionsByName } from '@/api/script/script'

export default {
  name: 'ScriptEditionAudit',
  components: {
    Template,
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      shopData: undefined,
      scriptData: undefined,
      shopOptions: [],
      scriptOptions: [],
      fetching: false,
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        shopId: null,
        scriptId: null,
        sourceText: null,
        img: null,
        status: null,
        reason: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '店铺',
          dataIndex: 'shopName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '剧本',
          dataIndex: 'scriptName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '审核状态',
          dataIndex: 'statusText',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询店铺剧本正版审核单列表 */
    getList () {
      this.loading = true
      console.log(this.queryParam)
      listScriptEditionAudit(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },
    fetchShopOptions(value) {
      loadOptionsByName({ name: value }).then(res => {
        this.shopOptions = res.data
      })
    },
    fetchScriptOptions(value) {
      loadScriptOptionsByName({ name: value }).then(res => {
        this.scriptOptions = res.data
      })
    },
    fetchShopChange(value) {
      this.shopData = value
      this.queryParam.shopId = ''
      this.shopData.forEach(e => {
        this.queryParam.shopId += (e.key + ',')
      })
      this.queryParam.shopId = this.queryParam.shopId.substring(0, this.queryParam.shopId.length - 1)
    },
    fetchScriptChange(value) {
      this.scriptData = value
      this.queryParam.scriptId = ''
      this.scriptData.forEach(e => {
        this.queryParam.scriptId += (e.key + ',')
      })
      this.queryParam.scriptId = this.queryParam.scriptId.substring(0, this.queryParam.scriptId.length - 1)
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        shopId: undefined,
        scriptId: undefined,
        sourceText: undefined,
        img: undefined,
        status: undefined,
        reason: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          return delScriptEditionAudit(ids)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {}
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('shop/script/edition/audit/export', {
            ...that.queryParam
          }, `script/edition/audit_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    },
    auditDetail(record) {
      this.$refs.createForm.ok(record)
    }
  }
}
</script>
