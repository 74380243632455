import request from '@/utils/request'

// 查询店铺剧本正版审核单列表
export function listScriptEditionAudit(query) {
  return request({
    url: '/shop/script/edition/audit/list',
    method: 'get',
    params: query
  })
}

// 查询店铺剧本正版审核单详细
export function getScriptEditionAudit(id) {
  return request({
    url: '/shop/script/edition/audit/' + id,
    method: 'get'
  })
}

// 新增店铺剧本正版审核单
export function addScriptEditionAudit(data) {
  return request({
    url: '/shop/script/edition/audit',
    method: 'post',
    data: data
  })
}

// 修改店铺剧本正版审核单
export function updateScriptEditionAudit(data) {
  return request({
    url: '/shop/script/edition/audit',
    method: 'put',
    data: data
  })
}

// 删除店铺剧本正版审核单
export function delScriptEditionAudit(id) {
  return request({
    url: '/shop/script/edition/audit/' + id,
    method: 'delete'
  })
}
export function audit(data) {
  return request({
    url: '/shop/script/edition/audit/audit',
    method: 'post',
    data: data
  })
}
