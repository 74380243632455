<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="店铺" prop="shopName" >
        <a-input v-model="form.shopName" :disabled="true" />
      </a-form-model-item>
      <a-form-model-item label="剧本" prop="scriptName" >
        <a-input v-model="form.scriptName" :disabled="true"/>
      </a-form-model-item>
      <a-form-model-item label="来源说明" prop="sourceText" >
        <a-input v-model="form.sourceText" type="textarea" :disabled="true" />
      </a-form-model-item>
      <a-form-model-item label="沟通截图" prop="img" >
        <a-upload
          name="roleImage"
          v-model="form.images"
          listType="picture-card"
          :fileList="form.images"
          class="avatar-uploader"
          :multiple="true"
          :show-upload-list="true">
        </a-upload>
      </a-form-model-item>
      <a-form-model-item label="状态" prop="status" >
        <a-select style="width: 250px" v-model="form.status" placeholder="请选择">
          <a-select-option value="2">通过</a-select-option>
          <a-select-option value="3">驳回</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="驳回原因" prop="reason" >
        <a-input v-model="form.reason" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            审批
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import {
  getScriptEditionAudit,
  audit
} from '@/api/shop/scriptEditionAudit'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        shopId: null,

        shopName: null,

        scriptName: null,

        scriptId: null,

        sourceText: null,

        img: null,

        status: 0,

        reason: null,

        creator: null,

        images: [],

        modifier: null,

        createTime: null,

        updateTime: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
      this.$emit('ok')
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.$emit('ok')
      this.reset()
    },
    ok(record) {
      this.open = true
      this.form = record
      this.form.status = undefined
      this.form.reason = undefined
      this.formTitle = '审批'

      var images = this.form.img.split(',')
      var img = []
      for (var i = 0; i < images.length; i++) {
        img.push({ status: 'done', url: images[i], uid: this.getUidRandom(), name: 'image.jpg' })
      }
      this.form.images = img
    },

    getUidRandom() {
      return Math.round(Math.random() * 80 + 20)
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,

        shopId: null,

        shopName: null,

        scriptName: null,

        scriptId: null,

        sourceText: null,

        img: null,

        status: 0,

        reason: null,

        creator: null,

        modifier: null,

        createTime: null,

        updateTime: null

      }
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getScriptEditionAudit(id).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      audit(this.form).then(res => {
        if (this.form.status === 2) {
          this.$message.success('通过成功')
        }
        if (this.form.status === 3) {
          this.$message.success('驳回成功')
        }
      }).finally(() => {
        this.open = false
        this.$emit('ok')
      })
    }
  }
}
</script>
